import React from 'react'

import intone from '../assets/images/sponsor/Intone.png'
import zoetis from '../assets/images/sponsor/zoetis.png'
import arconic from '../assets/images/sponsor/Arconic_Foundation.png'
import sothebys from '../assets/images/sponsor/Sothebys_International_Realty.png'
import ftc from '../assets/images/sponsor/ftc_logo.png'
import solidworks from '../assets/images/sponsor/Solidworks.png'
const Sponsors = (props) => (
  <section id="contact">
    <div className="inner">
      <section>
        <h2>Sponsored By</h2>
        <div className="box alt">
          <div className="grid-wrapper">
            <div className="col-4">
              <span className="image fit">
                <img src={zoetis} alt="" />
              </span>
            </div>
            <div className="col-4">
              <span className="image fit">
                <img src={arconic} alt="" />
              </span>
            </div>
            <div className="col-4">
              <span className="image fit">
                <img src={intone} alt="" />
              </span>
            </div>
            <div className="col-4">
              <span className="image fit">
                <img src={sothebys} alt="" />
              </span>
            </div>
            <div className="col-4">
              <span className="image fit">
                <img src={ftc} alt="" />
              </span>
            </div>
            <div className="col-4">
              <span className="image fit">
                <img src={solidworks} alt="" />
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="split">
        <section>
          <div className="contact-method">
            <span className="icon alt fa-envelope"></span>
            <h3>Email</h3>
            <a href="mailto:kryptonwarriors@gmail.com">
              kryptonwarriors@gmail.com
            </a>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-phone"></span>
            <h3>Phone</h3>
            <span>(973)-664-7210</span>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-home"></span>
            <h3>Location</h3>
            <span>
              Montville NJ
              <br />
              United States of America
            </span>
          </div>
        </section>
      </section>
    </div>
  </section>
)

export default Sponsors
