import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/">
            Home
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/team">
            Team
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/connect">
            Connect
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/motivate">
            Motivate
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/robot">
            Robot
          </Link>
        </li>
        {/* <li>
          <Link onClick={props.onToggleMenu} to="/build">
            Build
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/code">
            Code
          </Link>
        </li> */}
        {/* <li>
          <Link onClick={props.onToggleMenu} to="/design">
            Design
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/sponsors">
            Sponsors
          </Link>
        </li> */}
      </ul>
    </div>
    <a className="close" onClick={props.onToggleMenu} href="javascript:;">
      Close
    </a>
  </nav>
)

Menu.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Menu
