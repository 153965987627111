import React from 'react'

// import netlify from '../assets/images/other/Netlify.png'

const getYear = new Date().getFullYear()

const Footer = (props) => (
  <footer id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a
            href="https://instagram.com/kryptonwarriors16367"
            className="icon alt fa-instagram"
          >
            <span className="label">Instagram</span>
          </a>
        </li>
        {/* <li>
          <a
            href="https://github.com/kryptonwarriors"
            className="icon alt fa-github"
          >
            <span className="label">GitHub</span>
          </a>
        </li> */}
        <li>
          <a
            href="https://youtube.com/channel/UCi9EJiHX4nFPUSKw4xvQ3IQ"
            className="icon alt fa-youtube-play"
            aria-hidden="true"
          >
            <span className="label">YouTube</span>
          </a>
        </li>
        {/* <li>
          <a
            href="https://paypal.me/kryptonwarriors"
            className="icon alt fa-paypal"
          >
            <span className="label">PayPal</span>
          </a>
        </li> */}
      </ul>
      <ul className="copyright">
        <li>&copy; Krypton Warriors {getYear}</li>
      </ul>
    </div>
  </footer>
)

export default Footer
